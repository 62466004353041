import React from "react"

export default ({ color }) => {
    return (
        <svg width={185} height={23} style={{margin: '15px 0px'}}>
        <path
            d="M183.6 17.1l-.5 1c.4-1.1.4-2.3 0-3.5.5.8.7 1.6.5 2.5m.7-2.8c-1.9-4.6-7.5-7.6-11.8-9.5-6-2.6-12.4-3.6-18.9-4.1C120.8-2 87.2 3.5 55 9.4c-9.8 1.8-19.6 4-29.5 4.6-5.2.3-10.4.1-15.4-.9-3-.5-13.3-3.8-7-9 2.8-2.3 7.3.1 9.1 2.4.2.3.7 0 .5-.4-2-3-5.5-4.7-9-3.6-3.3 1-4.6 4-3.1 7 2.2 4.8 9.3 5.4 13.8 5.9 7.8.8 15.6 0 23.3-1.2C72.1 8.6 105.8 1.6 140.9 1.8c8.2 0 16.7.3 24.8 2.4a37.8 37.8 0 0 1 14.3 7.1c.6.4 1 1 1.5 1.5v.2c1 1.4 1.3 3.4.5 5-.7 1.4-2 2-3.5 2.3-.2 0-.3.2-.4.3-3.2.7-7.8-.7-9.1-2.8-.6-.8-2 0-1.4.8 4 6 20.3 4.5 16.7-4.3"
            fill={color || "#000"}
            fillRule="evenodd"
        />
        </svg>
    )
}
